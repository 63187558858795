<template>
  <div>
    <el-card shadow="never">
      <el-row>
        <el-input :popper-append-to-body="false" placeholder="借出单号" v-model="queryInfo.lendOrderCode" class="codeInput" clearable></el-input>
        <el-input :popper-append-to-body="false" placeholder="资产码" v-model="queryInfo.assetSn" class="codeInput" clearable></el-input>
        <el-button type="primary" @click="backNumOne" :loading="loading">
          <span v-if="loading">查询中</span>
          <span v-if="!loading">查询</span>
        </el-button>
        <MessageComponent ref="searchMessage"/>
      </el-row>
      <el-table :data="materialModifyData" style="width: 100%" max-height="700px" border stripe>
        <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="250px"></el-table-column>
        <el-table-column align="center" prop="materialCode" label="备件编码" width="110px"></el-table-column>
        <el-table-column align="center" prop="materialName" label="备件名称" width="300px"></el-table-column>
        <el-table-column align="center" prop="granularityName" label="备件粒度" width="100px"></el-table-column>
        <el-table-column align="center" prop="supplierCode" label="供应商代码" width="100px"></el-table-column>
        <el-table-column align="center" prop="supplierName" label="供应商名称" width="350px"></el-table-column>
        <el-table-column align="center" prop="asnNo" label="预入库单号" width="300px"></el-table-column>
        <el-table-column align="center" prop="assetSn" label="资产码" width="300px"></el-table-column>
        <el-table-column align="center" prop="materialSourceName" label="备件来源" width="100px"></el-table-column>
        <el-table-column align="center" prop="organizationCode" label="供应商代码" width="100px"></el-table-column>
        <el-table-column align="center" prop="supplierSn" label="供应商条码" width="250px"></el-table-column>
        <el-table-column align="center" prop="inLineTime" label="流程开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="currentStationName" label="实物所在工站" width="140px"></el-table-column>
        <el-table-column align="center" prop="currentStationUserName" label="当前工站操作人员" width="250px"></el-table-column>
        <el-table-column align="center" prop="currentStationStatusName" label="当前工站工作状态" width="140px"></el-table-column>
        <el-table-column align="center" prop="nextStationName" label="下一工站" width="200px"></el-table-column>
        <el-table-column align="center" prop="sortTime" label="分拣时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="sortUserName" label="分拣人员" width="250px"></el-table-column>
        <el-table-column align="center" prop="sortSn" label="出厂SN码" width="250px"></el-table-column>
        <el-table-column align="center" prop="sortBoxNo" label="箱号" width="100px"></el-table-column>
        <el-table-column align="center" prop="sortTestTypeName" label="检验类型" width="100px"></el-table-column>
        <el-table-column align="center" prop="sortLineName" label="生产线" width="100px"></el-table-column>
        <el-table-column align="center" prop="sortEnd" label="是否分拣结束" :formatter="isFormatter" width="120px"></el-table-column>
        <el-table-column align="center" prop="test1InTime" label="外观检验开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="test1OutTime" label="外观检验结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="test1UserName" label="外观检验人员" width="250px"></el-table-column>
        <el-table-column align="center" prop="test1Result" label="外观检验结果" width="300px" :formatter="testFormatter"></el-table-column>
        <el-table-column align="center" prop="test1CodeName" label="外观不良代码" width="300px"></el-table-column>
        <el-table-column align="center" prop="test1TroubleReason" label="外观不良原因" width="300px"></el-table-column>
        <el-table-column align="center" prop="test1Repairable" label="外观是否可修复" width="200px" :formatter="isFormatter"></el-table-column>
        <el-table-column align="center" prop="test1End" label="外观检验是否结束" :formatter="isFormatter" width="140px"></el-table-column>
        <el-table-column align="center" prop="review1InTime" label="外观不良复检开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="review1OutTime" label="外观不良复检结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="review1UserName" label="外观不良复检人员" width="300px"></el-table-column>
        <el-table-column align="center" prop="review1End" label="外观不良复检是否结束" :formatter="isFormatter" width="300px"></el-table-column>
        <el-table-column align="center" prop="test2InTime" label="电性能检验开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="test2OutTime" label="电性能检验结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="test2UserName" label="电性能检验人员" width="250px"></el-table-column>
        <el-table-column align="center" prop="test1Result" label="电性能检验结果" width="300px" :formatter="testFormatter"></el-table-column>
        <el-table-column align="center" prop="test2CodeName" label="电性能不良代码" width="300px"></el-table-column>
        <el-table-column align="center" prop="test2TroubleReason" label="电性能不良原因" width="300px"></el-table-column>
        <el-table-column align="center" prop="test2End" label="电性能检验是否结束" :formatter="isFormatter" width="160px"></el-table-column>
        <el-table-column align="center" prop="review2InTime" label="电性能不良复检开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="review2OutTime" label="电性能不良复检结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="review2UserName" label="电性能不良复检人员" width="300px"></el-table-column>
        <el-table-column align="center" prop="review2End" label="电性能不良复检是否结束" :formatter="isFormatter" width="300px"></el-table-column>
        <el-table-column align="center" prop="packInTime" label="包装操作开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="packOutTime" label="包装操作结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="packUserName" label="包装人员" width="250px"></el-table-column>
        <el-table-column align="center" prop="packEnd" label="包装是否结束" :formatter="isFormatter" width="120px"></el-table-column>
        <el-table-column align="center" prop="returnInTime" label="归还操作开始时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="returnOutTime" label="归还操作结束时间" width="200px"></el-table-column>
        <el-table-column align="center" prop="returnUserName" label="归还人员" width="150px"></el-table-column>
        <el-table-column align="center" prop="returnEnd" label="归还操作是否结束" :formatter="isFormatter" width="140px"></el-table-column>
        <el-table-column align="center" prop="inLineCnt" label="物品进站次数" width="120px"></el-table-column>
        <el-table-column align="center" prop="subClass" label="备件小类code" width="140px"></el-table-column>
        <el-table-column align="center" prop="subClassName" label="备件小类名称" width="240px"></el-table-column>
        <el-table-column align="center" prop="planCategory" label="计划大类code" width="140px"></el-table-column>
        <el-table-column align="center" prop="planCategoryName" label="计划大类名称" width="140px"></el-table-column>
        <el-table-column align="center" prop="chargeLineCode" label="运作产线code" width="140px"></el-table-column>
        <el-table-column align="center" prop="chargeLineName" label="运作产线名称" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.appearanceResult" label="外观检验结果" :formatter="testFormatter" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.electricityResult" label="电性能检验结果" :formatter="testFormatter" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.testResultName" label="检验结果" width="340px"></el-table-column>
        <el-table-column align="center" prop="testRecord.testResultRemark" label="检验结果描述" width="340px"></el-table-column>
        <el-table-column align="center" prop="testRecord.troubleCodeName" label="故障代码" width="300px"></el-table-column>
        <el-table-column align="center" prop="testRecord.troubleCodeRemark" label="故障代码描述" width="300px"></el-table-column>
        <el-table-column align="center" prop="testRecord.disposeOpinionName" label="处置意见" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.checkPersonName" label="检验员" width="250px"></el-table-column>
        <el-table-column align="center" prop="testRecord.remark" label="检验结果描述" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.testReturnWarehouseId" label="检验归还库房" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.testReturnStorageSectionId" label="检验归还库区" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.finallyStorageSectionId" label="最终入库库区" width="140px"></el-table-column>
        <el-table-column align="center" prop="testRecord.storageSectionId" label="入库库区" width="140px"></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button @click="modifyRow(scope.row)" type="primary" size="small">调整</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="currentPage"></el-pagination>
      </div>
    </el-card>

    <el-dialog title="修改数据" :visible.sync="editVisible" :before-close="handleClose" :close-on-click-modal="false" width="1350px">
      <el-form :model="editData" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="资产码：">
              <el-input :popper-append-to-body="false" v-model="editData.assetSn" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出厂SN码：">
              <el-input :popper-append-to-body="false" v-model="editData.sortSn" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="当前工站：">
              <el-select :popper-append-to-body="false" v-model="editData.currentStation" class="form-item">
                <el-option v-for="item in stationList" :key="item.value" :label="item.name" :value="item.value" :disabled="item.value=='sort' ||item.value=='end'"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前工站状态：">
              <el-select :popper-append-to-body="false" v-model="editData.currentStationStatus" class="form-item">
                <el-option v-for="item in stationStatusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下一工站：" v-if="editData.currentStationStatus=='finish'">
              <el-select :popper-append-to-body="false" v-model="editData.nextStation" class="form-item" clearable>
                <el-option v-for="item in stationList" :key="item.value" :label="item.name" :value="item.value" :disabled="item.value=='sort' ||item.value=='end'"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="箱号：">
              <el-input :popper-append-to-body="false" v-model="editData.sortBoxNo" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="贴纸技服码：">
              <el-input v-model.trim="editData.busItemInfo.stickerCode" maxlength="20" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维修商代码：">
              <el-select v-model="editData.test1RepairerCode" filterable clearable class="form-item">
                <el-option
                    v-for="item in repairerCodeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="外观检验结果：">
              <el-radio v-model="editData.test1Result" :label=true>正常</el-radio>
              <el-radio v-model="editData.test1Result" :label=false>不良</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="!editData.test1Result" label="外观故障代码：">
              <el-select :popper-append-to-body="false" v-model="editData.test1TroubleCode" class="form-item" filterable clearable>
                <el-option v-for="item in inputDict.troubleCodeList" :key="item.code" :label="item.codeName" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="!editData.test1Result" label="外观不良原因：">
              <el-input type="textarea" :rows="2" v-model="editData.test1TroubleReason" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editData.busItemInfo.keyboardAte">
          <el-col :span="8">
            <el-form-item label="外观等级：">
              <el-select v-model="editData.busItemInfo.appearanceLevel" class="form-item">
                <el-option v-for="item in appearanceLevelList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="editData.test1Result===true" label="是否修复OK：">
              <el-radio v-model="editData.test1Repairable" :label=true>是</el-radio>
              <el-radio v-model="editData.test1Repairable" :label=false>否</el-radio>
            </el-form-item>
            <el-form-item v-else label="不良类型：">
              <el-select v-model="editData.busItemInfo.test1NgType" class="form-item">
                <el-option v-for="item in test1NgTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="修复位置：" v-if="editData.test1Result===true&&editData.test1Repairable===true">
              <el-select v-model="editData.busItemInfo.test1RepairLocation" class="form-item">
                <el-option v-for="item in test1RepairLocationList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!editData.busItemInfo.keyboardAte">
          <el-col :span="8">
            <el-form-item v-if="!editData.test1Result" label="外观是否可修复：">
              <el-radio v-model="editData.test1Repairable" :label=true>是</el-radio>
              <el-radio v-model="editData.test1Repairable" :label=false>否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="电性能检验结果：">
              <el-radio v-model="editData.test2Result" :label=true>正常</el-radio>
              <el-radio v-model="editData.test2Result" :label=false>不良</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="!editData.test2Result" label="电性能故障代码：">
              <el-select :popper-append-to-body="false" v-model="editData.test2TroubleCode" class="form-item" filterable clearable>
                <el-option v-for="item in inputDict.troubleCodeList" :key="item.code" :label="item.codeName" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item v-if="!editData.test2Result" label="电性能不良原因：">
              <el-input type="textarea" :rows="2" v-model="editData.test2TroubleReason" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验结果：">
              <el-select :popper-append-to-body="false" v-model="editData.testResult" class="form-item">
                <el-option v-for="item in testResultList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置意见：">
              <el-select :popper-append-to-body="false" v-model="editData.disposeOpinion" class="form-item">
                <el-option v-for="item in disposeOpinionList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验结果描述：">
              <el-input type="textarea" :rows="2" v-model="editData.testResultRemark" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="捷思目标库区：">
              <el-select v-model="editData.storeType" filterable placeholder="请选择捷思目标库区" class="form-item">
                <el-option v-for="item in storeTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联想目标库区：" v-if="this.editData.lenovoJudgeStore">
              <el-select v-model="editData.lenovoStore" v-if="!storeLoading" filterable placeholder="请选择联想目标库区" class="form-item">
                <el-option v-for="item in lenovoStoreList" :key="item.id" :label="item.name" :value="''+item.id"></el-option>
              </el-select>
              <span style="color: orange;" v-if="storeLoading">联想库区加载中...</span>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip" v-if="this.editData.lenovoJudgeStore">提示：<br>
          1、如果修改了外观、电性能检验结果信息，保存后请重新打开该编辑窗口"核对联想目标库区"。<br>
          2、如果联想目标库区显示为"数字"而非"库区名称"，请重新选择正确的联想目标库区，否则检验记录上传会提示"对应的库区错误"。
        </div>
        <el-row>
          <el-col :span="24" style="text-align: center;padding-bottom: 20px">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" :loading="editLoading" @click="editClick">确定</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "MaterialDetailModify",
  components: {MessageComponent},
  data() {
    return {
      materialModifyData: [],
      queryInfo: {
        pageNo: 1,
        lendOrderCode: '',
        assetSn: ''
      },
      inputDict: {
        userList: [],
        troubleCodeList: [],
      },
      trouble: {
        subClass: '',
      },
      editVisible: false,
      editData: {
        id: '',
        assetSn: '',
        sortSn: '',
        currentStation: '',
        nextStation: '',
        currentStationStatus: '',
        currentStationUserId: '',
        sortBoxNo: '',
        test1Result: '',
        test1TroubleCode: '',
        test1TroubleReason: '',
        test1Repairable: '',
        test2Result: '',
        test2TroubleCode: '',
        test2TroubleReason: '',
        testResult: '',
        testResultRemark: '',
        disposeOpinion: '',
        storeType: '',
        lenovoStore: '',
        test1RepairerCode: '',
        busItemInfo: {
          stickerCode: '',
          keyboardAte: undefined,
          appearanceLevel: '',
          test1NgType: '',
          test1RepairLocation: '',
        }
      },
      lenovoStoreList: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      editLoading: false,
      storeLoading: false,
      loading: false
    }
  },
  computed: {
    stationStatusList() {
      return this.$store.getters.getDictList('stationStatus') || [];
    },
    stationList() {
      return this.$store.getters.getDictList('station') || [];
    },
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
    repairerCodeList() {
      return this.$store.getters.getDictList('repairerCode') || [];
    },
    appearanceLevelList() {
      return this.$store.getters.getDictList('appearanceLevel') || [];
    },
    test1NgTypeList() {
      return this.$store.getters.getDictList('test1NgType') || [];
    },
    test1RepairLocationList() {
      return this.$store.getters.getDictList('test1RepairLocation') || [];
    },
  },
  methods: {
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.getBusItemList();
    },
    async getBusItemList() {
      this.materialModifyData = []
      this.loading = true;
      const {data: res} = await this.$axios.post('busItem/busItemSelectList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error('获取用户列表失败！');
      }
      this.materialModifyData = res.data.records || [];
      this.total = res.data.total;
      this.pageSize = res.data.size;
      return;
    },
    async getInputList() {
      const {data: res2} = await this.$axios.get('user/getAllUser');
      if (res2.code !== 0) {
        return this.$message.error('获取用户名称失败！');
      }
      this.inputDict.userList = res2.data;
    },
    async getTroubleNameList() {
      const {data: res} = await this.$axios.post('busTrouble/getAllBusTrouble?subClass=' + this.trouble.subClass);
      if (res.code !== 0) {
        return this.$message.error('获取不良代码名称失败！');
      }
      this.inputDict.troubleCodeList = res.data;
    },

    async editClick() {
      if (!this.editData.assetSn) {
        return this.$message.error('资产码不能为空');
      }
      this.editLoading = true;
      const {data: res} = await this.$axios.post('busItem/editBusItem', this.editData);
      this.editLoading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.editVisible = false;
      this.$message.success('修改成功');
      this.getBusItemList();
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    testFormatter(row, column, cellValue) {
      if (cellValue) {
        return '正常';
      }
      if (cellValue == false) {
        return '不良';
      }
    },
    modifyRow(row) {
      this.editVisible = true;
      this.editLoading = false;
      this.trouble.subClass = row.subClass;
      this.getTroubleNameList();
      this.editData.id = row.id;
      this.editData.assetSn = row.assetSn;
      this.editData.sortSn = row.sortSn;
      this.editData.currentStation = row.currentStation;
      this.editData.currentStationStatus = row.currentStationStatus;
      this.editData.currentStationUserId = row.currentStationUserId;
      this.editData.nextStation = row.nextStation;
      this.editData.sortBoxNo = row.sortBoxNo;
      this.editData.busItemInfo.stickerCode = row.busItemInfo?.stickerCode;
      this.editData.busItemInfo.keyboardAte = row.busItemInfo?.keyboardAte;
      this.editData.busItemInfo.appearanceLevel = row.busItemInfo?.appearanceLevel;
      this.editData.busItemInfo.test1NgType = row.busItemInfo?.test1NgType;
      this.editData.busItemInfo.test1RepairLocation = row.busItemInfo?.test1RepairLocation;
      this.editData.test1RepairerCode = row.test1RepairerCode;
      this.editData.test1Result = row.test1Result;
      this.editData.test1TroubleCode = row.test1TroubleCode;
      this.editData.test1TroubleReason = row.test1TroubleReason;
      this.editData.test1Repairable = row.test1Repairable;
      this.editData.test2Result = row.test2Result;
      this.editData.test2TroubleCode = row.test2TroubleCode;
      this.editData.test2TroubleReason = row.test2TroubleReason;
      this.editData.testResult = row.testResult;
      this.editData.testResultRemark = row.testResultRemark;
      this.editData.disposeOpinion = row.disposeOpinion;
      this.editData.storeType = row.storeType;
      this.editData.lenovoStore = row.lenovoStore;
      this.editData.lenovoJudgeStore = row.lenovoJudgeStore;

      if (row.lenovoJudgeStore) {
        if (row.currentStation == 'pack' || row.currentStation == 'return' || row.nextStation == 'pack') {
          this.storeLoading = true
          this.$axios.post('/busItem/getLenovoStore', row.assetSn).then(response => {
            this.storeLoading = false;
            let res = response.data;
            if (res.code !== 0) {
              return this.$message.error(res.message);
            }
            this.lenovoStoreList = res.data || [];
          }, error => {
            this.storeLoading = false;
            return this.$message.error('调研联想判保接口失败，' + error.message);
          });
        } else {
          return this.$message.warning('检验未结束，不调用联想判保接口');
        }

      }
    },
    handleClose() {
      this.editVisible = false;
      this.backNumOne()
    },
    backNumOne() {
      this.loading = true;
      this.currentPage = 1;
      this.queryInfo.pageNo = 1;
      this.getBusItemList();
    }
  },
  mounted() {
    this.getInputList();
  }
}
</script>

<style scoped>
.form-item {
  width: 280px;
}

.codeInput {
  width: 300px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.tip {
  padding-left: 200px;
  padding-bottom: 10px;
}
</style>